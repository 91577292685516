<template>
  <v-list-item>
    <v-btn
      block
      v-bind="$attrs"
      @click="$emit('click')"
      v-text="$attrs.label"
    />
  </v-list-item>
</template>

<script>
export default {
  name: 'ListButton'
};
</script>

<style></style>
